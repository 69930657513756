div.embeddedServiceHelpButton div.helpButton {
    width: 200px;
    height: 44px;
    right: 20px;
    bottom: 0;

    & button.uiButton {
        justify-content: center;
        padding: 10px;
        background-color: #E21C11;
        border-radius: 4px 4px 0px 0px;
        font-family: "Salesforce Sans", sans-serif;

        &:focus {
            outline: 1px solid #E21C11;
        }

        &.helpButtonDisabled {
            background-color: grey;

            & .message {
                margin: 0px;
            }
        }

        & .helpButtonLabel {
            font-family: 'SF Pro Text';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            flex: none;
            order: 1;
            flex-grow: 0;
        }

        & .embeddedServiceIcon {
            height: 16px;
            width: 16px;
        }

        & .message {
            background-color: inherit;
            color: inherit;
            border: none;
        }
    }
}

@font-face {
    font-family: 'Salesforce Sans';
    src: url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.woff') format('woff'),
    url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.ttf') format('truetype');
}
